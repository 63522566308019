import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ComplexAppBar from "./component/ComplexAppBar";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";

const theme = createTheme({
    palette: {
        primary: {
            main: 'rgba(11, 95, 128, 1)',
        },
        background: {
            default: 'rgba(22, 120, 158, 1)',
        }
    },
});

function App() {
    const { t } = useTranslation();
    useEffect(() => {
        document.title = t('app.title');
    }, [t]);

    const appBarRef = useRef(null);
    const [appBarHeight, setAppBarHeight] = useState(0);

    useEffect(() => {
        if (appBarRef.current) {
            setAppBarHeight(appBarRef.current.clientHeight);
        }
    }, [appBarHeight]);

    return (
        <ThemeProvider theme={theme}>
            <ComplexAppBar innerRef={appBarRef}/>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: `calc(100vh - ${appBarHeight}px)`,
                    backgroundColor: theme.palette.background.default,
                }}
            >
                <img src="logo-big.png" alt="Logo" style={{ maxWidth: '100%', maxHeight: '100vh' }} />
            </Box>
        </ThemeProvider>
    );
}

export default App;
