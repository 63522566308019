import * as React from 'react';
import { alpha, styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from "react-i18next";
import LanguageChooser from "./LanguageChooser";

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));

export default function ComplexAppBar({ innerRef }) {
    const { t } = useTranslation();

    return (
        <Box ref={innerRef}>
            <AppBar position="static" sx={{ padding: '10px' }}>
                <Toolbar sx={{ flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start',  flexDirection: { xs: 'column-reverse', sm: 'row' } }}>
                        <img src="logo192.png" alt="Logo" style={{ height: 50 }}/>
                        <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            sx={{ margin: 1 }}
                        >
                            {t('app.title')}
                        </Typography>
                    </Box>
                    <Box>
                        <Search>
                            <SearchIconWrapper>
                                <SearchIcon/>
                            </SearchIconWrapper>
                            <StyledInputBase
                                placeholder={t('app.search')}
                                inputProps={{ 'aria-label': 'search' }}
                            />
                        </Search>
                    </Box>
                    <Box sx={{ paddingTop: { xs: '10px', sm: '0px' }, display: { xs: 'flex', sm: 'flex' }, justifyContent: 'flex-end' }}>
                        <LanguageChooser defaultLanguage='en' supportedLanguages={['en', 'sk', 'cz']}></LanguageChooser>
                    </Box>
                </Toolbar>
            </AppBar>
        </Box>
    );
}
