import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
    .use(HttpBackend) // načíta preklady z backendu (alebo lokálneho súboru)
    .use(LanguageDetector) // detekuje jazyk používateľa
    .use(initReactI18next) // inicializuje react-i18next
    .init({
        fallbackLng: "en", // východzí jazyk
        interpolation: {
            escapeValue: false, // nepoužívať escape pre hodnoty
        },
        // prípadne iné nastavenia
    });

export default i18n;
