import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import ReactCountryFlag from "react-country-flag";
import { changeLanguage } from "i18next";

function LanguageChooser({ defaultLanguage = 'en', supportedLanguages = ['en'] }) {
    const { i18n } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
    // Otherwise the flag would be the same as the language code
    const specificCountryCodeByLanguage = {
        'en': 'GB'
    }

    const changeCurrentLanguage = (language) => {
        changeLanguage(language)
            .then(i => setCurrentLanguage(language));
    }

    useEffect(() => {
            const supportedLanguageIsSet = supportedLanguages.filter(sl => sl === currentLanguage).length > 0;
            if (!supportedLanguageIsSet) {
                changeCurrentLanguage(defaultLanguage);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [currentLanguage]
    );

    let commonFlagStyles = {
        fontSize: '30px',
        borderRadius: '50%'
    };

    return (
        <>
            {supportedLanguages.map((lang, index) =>
                <ReactCountryFlag
                    onClick={currentLanguage === lang ? undefined : () => changeCurrentLanguage(lang)}
                    countryCode={specificCountryCodeByLanguage[lang] || lang}
                    svg
                    key={lang}
                    style={{
                        ...commonFlagStyles,
                        ...(currentLanguage !== lang ? { cursor: 'pointer' } : { filter: 'grayscale(100%)' }),
                        ...(index !== 0 ? { marginLeft: '10px' } : undefined)
                    }}
                />)}
        </>
    );
}

export default LanguageChooser;
